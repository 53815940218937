header.grid-container {
	margin-bottom: 30px;
	margin-top: 10px;
}

header {
	background-color: #241739;
	height: 65px;

	> .grid-x {
		height: 65px;

		@include breakpoint(small only) {
			height: 95px;
		}
	}

	h1 {
		color: #fff;
		font-size: 20px;
		margin: 0;

		small {
			color: #fff;
			font-size: 12px;
		}
	}

	.header-title-search {
		position: relative;
		display: flex;
		gap: 40px;
		overflow: visible;
		z-index: 4;

		@include breakpoint(small only) {
			order: 3;
			margin-bottom: 10px;
		}

		.header-search-container {
			position: relative;
			flex-grow: 1;

			input {
				margin: 0;
				background: transparent;
				border: solid 1px rgba(255,255,255, 0.4);
				font-size: 20px;
				padding: 5px 10px;
				color: #fff;
				max-width: 400px;
			}

			.search-results {
				position: absolute;
				max-width: 100%;
				top: 100%;
				left: 0;
				right: 0;
				background: #fff;
				border: solid 1px rgba(0,0,0,0.09);

				.result {
					display: block;
					padding: 20px;
					font-size: 16px;
					border-bottom: solid 1px rgba(0,0,0,0.09);

					&:hover, &.hover {
						background-color: rgba(163,163,163,0.09);
					}

					&:last-child {
						border-bottom: 0;
					}
				}
			}
		}
	}

	.buttons {
		@include breakpoint(small only) {
			order: 2;
		}

		.button {
			font-size: 20px;
			margin: 0 20px 0 0;
			padding: 0;
		}
	}

	#settings-dropdown,
	#account-dropdown {
		ul {
			width: 100%;
		}
		li {
			width: 100%;
			text-align: left;
			border-bottom: solid 1px #eceff1;

			&:last-child {
				border-bottom: 0;
			}

			&:hover {
				background-color: #eceff1;
			}

			a {
				color: #6c7177;
			}
		}
	}
}

.title-bar.mobile {
	background-color: #fff;
	color: #6c7177;
	border-bottom: 1px solid rgba(0,0,0,0.11);

	button {
		font-size: 16px;
		margin-right: 10px;
	}
}

#mobile-main-menu {
	background-color: #fff;
	padding: 0;
	border-bottom: 1px solid rgba(0,0,0,0.11);

	ul {
		background-color: #fff;

		li:first-child {
			border-top: 0;
		}
	}
}