.page-logs {
	.logfiles-overview {
		ul {
			list-style: none;
		}

		.logview {
			min-height: 100px;
			border: 1px solid rgba(10,10,10,0.25);
			background-color: #f1f2f3b8;
			margin-right: 10px;
			padding: 10px;
			font-family: monospace;
			white-space: pre;
			margin-bottom: 10px;
		}
	}
}