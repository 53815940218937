.labdata-container {
	.padding-right {
		padding-right: 15px;
	}
}

.table-container {
	padding: 0;

	h2 {
		padding: 10px;
		margin: 0 !important;
	}

	.filterblock {
		padding: 20px;

		.fa-arrow-up {
			color: #4caf50;
		}

		.fa-arrow-down {
			color: #e6352b;
		}

		.fa-circle {
			color: #03a9f4;
			font-size: 11px;
		}

	}

	table {
		font-size: 12px;

		thead {
			th {
				color: #4F5257;
				border-bottom: 1px solid #ddd;
			}
		}

		th[data-type],
		td[data-type] {
			display: none;
		}

		td {
			max-width: 400px;

			&.nowrap {
				white-space: nowrap;
			}

			&.color-green {
				color: $lighthouse-green;
			}

			&.color-orange {
				color: $lighthouse-orange;
			}

			&.color-red {
				color: $lighthouse-red;
			}

			.fa-arrow-up {
				color: #4caf50;
			}

			.fa-arrow-down {
				color: #e6352b;
			}

			.fa-circle {
				color: #03a9f4;
				font-size: 11px;
			}

			&.td-spinner {
				text-align: center;
				padding-top: 40px;
				padding-bottom: 40px;
				font-size: 48px;
			}

			.fas.fa-heart {
				color: $lighthouse-orange;
			}

			.fa-heart {
				cursor: pointer;
			}
		}

		.first-column {
			border-left: solid 1px #ddd;
		}
	}

	.filter-container {
		display: none;
	}

	td.td-buttons {
		white-space: nowrap;
	}

	.pagination {
		position: relative;

		.items-per-page {
			position: absolute;
			right: 0;
			top: 0;
			min-width: 205px;
			padding-right: 10px;

			select {
				margin: 0;
			}

			@media screen and (max-width: 1280px) {
				position: relative;
				max-width: 205px;
				margin: 0 auto 10px auto;
			}
		}
	}
}

.icon-button {
	display: block;
	font-size: 16px;
	color: #6c7177;
	margin-left: 10px;
	margin-top: 10px;
}

.option-buttons > :first-child {
	margin-right: 10px;
}

.button-sheets,
input.search,
.icon-button {
	float: right;
}

.button-sheets {
	margin-top: 10px;

	img {
		width: 20px;
		margin-top: -1px;
		margin-left: 10px;
		cursor: pointer;
	}
}

input.search {
	margin-top: 5px;
	opacity: 0;
	-webkit-transition: all .5s ease;
	-moz-transition: all .5s ease;
	transition: all .5s ease;
	width:0px;
}

input.search.expanded {
	border-radius: 0;
	width: 300px;
}

input.search.expanded {
	opacity: 1;
}