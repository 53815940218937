$lighthouse-red: rgb(255, 78, 66);
$lighthouse-orange: rgb(255, 164, 0);
$lighthouse-green: rgb(12, 206, 107);

$bgcolor-alt: #eaeaea;

.no-margin-bottom {
	margin-bottom: 0;
}

.copyright {
	font-size: 10px;
}

.add-button {
	@include button-style(#ffffff, #f5f5f5, #6c7177);
	border: solid 1px #eceff1;
	margin: 0;

	&:after {
		margin-left: 5px;
		border-left: solid 1px rgba(0, 0, 0, .1);
		content: '\f067';
		display: inline-block;
		font-family: "Font Awesome 6 Pro";
		font-weight: 700;
		padding-left: 10px;
		color: #6c7177;
	}
}

a.no-pointer-events {
	* {
		pointer-events: none;
	}

}

.pre {
	white-space: pre;
	font-family: monospace;
	font-size: 12px;
	overflow-x: scroll;
}

.filter-reveal.alert-reveal {
	.reveal-header {
		margin-bottom: 20px;
	}

	.button-bar {
		margin-top: 20px;
	}

	i.fa-exclamation-triangle {
		color: #e6352b;
		font-size: 40px;
	}

	i.fa-check-square {
		color: green;
		font-size: 40px;
	}
}

.comments-button {
	@include button-style(#ffffff, #f5f5f5, #6c7177);
	border: solid 1px #eceff1;
	margin: 0;

	&:after {
		margin-left: 5px;
		border-left: solid 1px rgba(0, 0, 0, .1);
		content: '\f075';
		display: inline-block;
		font-family: "Font Awesome 6 Pro";
		font-weight: 700;
		padding-left: 10px;
		color: #6c7177;
	}
}

.report-button {
	@include button-style(#ffffff, #f5f5f5, #6c7177);
	border: solid 1px #eceff1;
	margin: 0;

	&:after {
		margin-left: 5px;
		border-left: solid 1px rgba(0, 0, 0, .1);
		content: '\f1c1';
		display: inline-block;
		font-family: "Font Awesome 6 Pro";
		font-weight: 700;
		padding-left: 10px;
		color: #6c7177;
	}
}

.settings-button {
	@include button-style(#ffffff, #f5f5f5, #6c7177);
	border: solid 1px #eceff1;
	margin: 0;

	&:after {
		margin-left: 5px;
		border-left: solid 1px rgba(0, 0, 0, .1);
		content: '\f013';
		display: inline-block;
		font-family: "Font Awesome 6 Pro";
		font-weight: 700;
		padding-left: 10px;
		color: #6c7177;
	}
}

.sitemap-button {
	@include button-style(#ffffff, #f5f5f5, #6c7177);
	border: solid 1px #eceff1;
	margin: 0;

	&:after {
		margin-left: 5px;
		border-left: solid 1px rgba(0, 0, 0, .1);
		content: '\f0e8';
		display: inline-block;
		font-family: "Font Awesome 6 Pro";
		font-weight: 700;
		padding-left: 10px;
		color: #6c7177;
	}
}


.history-button {
	@include button-style(#ffffff, #f5f5f5, #6c7177);
	border: solid 1px #eceff1;
	margin: 0;

	&:after {
		margin-left: 5px;
		border-left: solid 1px rgba(0, 0, 0, .1);
		content: '\f1da';
		display: inline-block;
		font-family: "Font Awesome 6 Pro";
		font-weight: 700;
		padding-left: 10px;
		color: #6c7177;
	}
}

.external-link {
	span {
		display: inline-block;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
		max-width: 90%;
	}
	&:after {
		content: '\f35d';
		display: inline-block;
		font-family: "Font Awesome 6 Pro";
		font-weight: 700;
		font-size: 12px;
		padding-left: 10px;
		position: relative;
		top: -5px;
	}
}

.sidebar {
	border-right: 1px solid rgba(0, 0, 0, .11);
	height: 100vw;
	color: #6c7177;

	@include breakpoint(small only) {
		height: auto;
	}

	.person {
		margin: 30px 0;
	}

	.menu {
		li {
			border-top: solid 1px rgba(0, 0, 0, .09);

			a {
				color: #6c7177;
				padding: 15px;

				&:hover {
					background-color: hsla(0, 0%, 64%, .09);
				}
			}

			&.is-active > a {
				background-color: hsla(0, 0%, 64%, .026);

				&:hover {
					background-color: hsla(0, 0%, 64%, .09);
				}
			}

			&.sub {
				padding-left: 10px;
			}
		}
	}
}

.content-container {
	background-color: #f1f2f3;

	@include breakpoint(small only) {
		padding-bottom: 320px;
	}

}

.img-circle {
	border-radius: 50%;
}

.thumb64 {
	width: 64px;
	height: 64px;
}

.thumb48 {
	width: 48px;
	height: 48px;
}

.thumb32 {
	width: 32px;
	height: 32px;
}

.content-header {
	background-color: #fff;
	border-bottom: 1px solid rgba(0, 0, 0, .11);
	padding: 20px;

	h2 {
		font-size: 24px;
		color: #6c7177;
		margin: 0;
	}

	small {
		color: hsla(0, 0%, 64%, .92);
		letter-spacing: .01em;
		font-size: 85%;
	}
}

.content-body {
	padding: 20px;
}

.activity {
	color: #4F5257;

	h2 {
		font-size: 18px;
		margin: 0 0 30px 0;
	}

	.activity-box {
		padding: 0 0 0 20px;
		position: relative;
		margin-bottom: 20px;

		span.monospaced {
			font-family: "Courier New";
		}

		&:before {
			display: block;
			position: absolute;
			content: '\f111';
			font-family: "Font Awesome 6 Pro";
			font-weight: 900;
			font-size: 10px;
			left: 0;
			top: 3px;
			color: #03a9f4;
		}

		&.added:before {
			color: #4caf50;
		}

		&.removed:before,
		&.lost:before {
			color: #e6352b;
		}

		small {
			color: hsla(0, 0%, 64%, .92);
			letter-spacing: .01em;
			font-size: 85%;
		}

		p.comment {
			border-left: 1px solid hsla(0, 0%, 64%, .32);
			padding-left: 16px;
			font-size: 12px;
		}

		p.project {
			font-size: 12px;
		}

		p.no-margin {
			margin-bottom: 0;
		}
	}
}

.comment-icon {
	display: inline-block;
	position: relative;
	font-size: 16px;

	span {
		position: absolute;
		top: 3px;
		right: 5px;
		font-size: 10px;
	}
}

.edit-icon {
	display: inline-block;
	margin-right: 10px;
}

.reveal {
	h2 {
		font-size: 18px;
		margin-bottom: 20px;
	}
}

#linkCommentsReveal,
#commentsReveal {
	.comment {
		margin-bottom: 30px;

		.comment-text:after {
			content: '';
			display: block;
			border-top: 1px solid hsla(0, 0%, 64%, .32);
			margin: 20px 60px 0;
		}

		.comment-text.no-line:after {
			display: none;
		}
	}

	.no-comments {
		padding-top: 30px;
		padding-bottom: 30px;
	}

	small {
		color: hsla(0,0%,64%,.92);
		letter-spacing: .01em;
		font-size: 85%;
	}

	.add-comment {
		border-top: 1px solid hsla(0, 0%, 64%, .32);
		padding-top: 20px;
	}


	textarea {
		font-size: 12px;
	}
}

#addlinkReveal {
	.comment {
		margin-bottom: 30px;

		.comment-text:after {
			content: '';
			display: block;
			border-top: 1px solid hsla(0, 0%, 64%, .32);
			margin: 20px 60px 0;
		}

		.comment-text.no-line:after {
			display: none;
		}
	}

	small {
		color: hsla(0,0%,64%,.92);
		letter-spacing: .01em;
		font-size: 85%;
	}

	.add-comment {
		border-top: 1px solid hsla(0, 0%, 64%, .32);
		padding-top: 20px;
	}

	label, input[type="text"], textarea, legend {
		font-size: 12px;
	}

	label {
		color: #6c7177;
	}

	.tier2backlink {
		display: none;
	}

	.input-group.date {
		width: 130px !important;
	}
}

#reportReveal {
	.comment {
		margin-bottom: 30px;

		.comment-text:after {
			content: '';
			display: block;
			border-top: 1px solid hsla(0, 0%, 64%, .32);
			margin: 20px 60px 0;
		}

		.comment-text.no-line:after {
			display: none;
		}
	}

	small {
		color: hsla(0,0%,64%,.92);
		letter-spacing: .01em;
		font-size: 85%;
	}

	.add-comment {
		border-top: 1px solid hsla(0, 0%, 64%, .32);
		padding-top: 20px;
	}

	select, option, input[type="text"], textarea {
		font-size: 12px;
	}

	.report-container {
		border-top: 1px solid hsla(0, 0%, 64%, .32);
		padding-top: 20px;

		.download-report {
			&:before {
				margin-right: 15px;
				content: '\f1c1';
				display: inline-block;
				font-family: "Font Awesome 6 Pro";
				font-weight: 700;
				padding-left: 10px;
				color: #6c7177;
				font-size: 16px;
			}
		}
	}
}

#settingsReveal {
	select, option, input[type="text"], textarea {
		font-size: 12px;
	}

	.button-row {
		margin-top: 60px;
	}

	.button-row-top {
		margin-top: 20px;
		margin-bottom: 40px;
		padding-bottom: 20px;
		border-bottom: solid 1px #6c7177;
	}
}

.login-container {
	&:before {
		content: '';
		display: block;
		position: fixed;
		width: 100%;
		height: 100%;
		background: url('/img/background_searchking.png') no-repeat;
		background-position: center;
		background-size: cover;
		filter: blur(5px);
		z-index: -1;
	}
	.grid-container {
		margin-top: 10%;

	}

	.login-callout {
		background: rgba(255,255,255,.85);
		padding: 40px 40px 0;
	}

	.button-container {
		padding: 60px 0;
		margin-top: 30px;
	}

}

.button.save-button {
	margin: 0 15px 0 0;
}

.saved {
	color: #4caf50;
}

.margin-top {
	margin-top: 15px;
}

.circle-item {
	position: relative;
	width: 50px;
	height: 50px;
	border-radius: 50%;
	border: 1px solid #fff;
	background-color: #FFF;
	margin-left: auto;
	margin-right: auto;
}

.radial-inner-bg {
	border-radius: 50%;
	width: 40px;
	height: 40px;
	top: 4px;
	left: 4px;
	background: #FFF;
	position: absolute;
	text-align: center;
	padding-top: 6px;
	font-weight: bold;
	font-size: 18px;
}


.folders-holder {
	border: solid 1px #cecece;
	background-color: #fff;
	width: 302px;
	overflow: hidden;
	margin-bottom: 30px;

	.header {
		font-size: 18px;
		padding-top: 10px;
		padding-bottom: 10px;
		background-color: #ededed;
		position: relative;
		border-bottom: solid 1px #cecece;

		.name {
			width: 190px;
			margin-left: 38px;
			overflow: hidden;
			text-overflow: ellipsis;
			padding-top: 4px;
			white-space: nowrap;
		}

		.back {
			position: absolute;
			left: 10px;
			top: 15px;
			max-width: 38px;
			display: none;

			.back-button {
				color: $body-font-color;
			}
		}

		.sync {
			position: absolute;
			right: 47px;
			top: 15px;
			max-width: 38px;
			display: none;
			font-size: 14px;

			a {
				color: #cecece;

				&:hover {
					color: $body-font-color;
				}
			}
		}

		.close {
			position: absolute;
			top: 14px;
			right: 25px;
			font-size: 17px;

			a {
				color: $body-font-color;

				&:hover {
					color: #cecece;
				}
			}
		}

	}

	.foot {
		border-top: solid 1px #cecece;
		padding: 10px;
		position: relative;

		.msg {
			position: absolute;
			top: 20px;
			left: 10px;
			color: $lighthouse-red;
			font-size: 10px;
			display: none;
		}

		.button {
			margin: 0;
		}
	}

	.folder-container {
		width: 300px;
		height: 300px;
		overflow: hidden;

		.folder-slide {
			overflow-y: scroll;
			width: 300px;
			height: 300px;
		}

		.folder-slide.loading {
			width: 100%;
			text-align: center;
			vertical-align: middle;
			font-size: 32px;
			padding-top: 120px;

			p {
				font-size: 12px;
				margin: 0;
			}
		}
	}

	.folder {
		padding-top: 5px;
		padding-bottom: 5px;
		min-height: 40px;
		cursor: pointer;
		position: relative;

		.name {
			width: 200px;
			margin-left: 38px;
			overflow: hidden;
			text-overflow: ellipsis;
			padding-top: 4px;
			white-space: nowrap;
		}

		.icon {
			position: absolute;
			left: 10px;
			top: 10px;
			max-width: 38px;
		}

		.chevron {
			position: absolute;
			right: 25px;
			top: 5px;
			max-width: 38px;
		}

		.to-children {
			display: none;
			width: 20px;
			padding: 2px 5px;
			border: solid 1px #f7f7f7;
			color: #494949;

			&:hover {
				border: solid 1px #494949;
			}
		}

		&:hover {
			background-color: #f7f7f7;

			.to-children {
				display: block;
			}
		}

		&.active {
			background-color: #f7f7f7;
		}
	}
}

$step: 1;
$loops: 100;
$increment: (360 / $loops);
$half: round($loops / 2);
@for $i from 0 through $loops {
	.progress-#{$i * $step} {
		$bgcolor: $lighthouse-green;
		@if $i < 90 {
			$bgcolor: $lighthouse-orange;
		}
		@if $i < 50 {
			$bgcolor: $lighthouse-red;
		}


		@if $i < 50 {
			$nextdeg: 90deg + ( $increment * $i );
			background-image: linear-gradient(90deg, $bgcolor-alt 50%, transparent 50%, transparent), linear-gradient($nextdeg, $bgcolor 50%, $bgcolor-alt 50%, $bgcolor-alt);

			.radial-inner-bg {
				color: $bgcolor;
			}
		}
		@else {
			$nextdeg: -90deg + ( $increment * ( $i - $half ) );
			background-image: linear-gradient($nextdeg, $bgcolor 50%, transparent 50%, transparent), linear-gradient(270deg, $bgcolor 50%, $bgcolor-alt 50%, $bgcolor-alt);

			.radial-inner-bg {
				color: $bgcolor;
			}
		}
	}
}

table {
	td, th {
		input[type="checkbox"] {
			margin-bottom: 0;
		}
	}
}

.custom-tooltip {
	position: absolute;
	background-color: #333;
	color: #fff;
	padding: 5px;
	border-radius: 4px;
	font-size: 12px;
	z-index: 1000;
	max-width: 200px;
}

.nowrap {
	white-space: nowrap;
}

i.help-icon {
	cursor: pointer;
	margin-left: 10px;

	&:hover {
		font-weight: 900;
	}
}

div.help-text-reveal {
	.help-content {
		padding: 20px;
	}

	.padding-left-right {
		padding-left: 30px;
		padding-right: 30px;
	}

	ul.toc {
		font-size: 12px;
		margin-bottom: 20px;

		ul {
			padding-left: 20px;
		}

	}

	h1 {
		font-size: 20px;
		font-weight: bold;
	}
	h2 {
		font-size: 18px;
		font-weight: bold;
		margin-bottom: 0;
		margin-top: 40px;
	}
	h3 {
		font-size: 16px;
		font-weight: bold;
		margin-bottom: 0;
		margin-top: 30px;
	}

	img {
		box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); // Lichte schaduw
		border-radius: 4px; // Optioneel: afgeronde hoeken
	}

}

.editor-container {
	margin-bottom: 20px;
	border: solid 1px #c5c7d0;
	border-radius: 4px;

	.editor {
		height: 200px;
		width: auto;
		overflow: auto;
		text-align: left;
		padding: 10px;
		outline: none;

		&.text-mode {
			white-space: pre-wrap;
			font-family: monospace;
		}

		h1 {
			font-size: 20px;
		}

		h2 {
			font-size: 18px;
		}

		h3 {
			font-size: 16px;
		}

	}

	.buttons-panel {
		border-bottom: solid 1px #c5c7d0;
		padding-left: 5px;
	}

	.buttons-panel button {
		display: inline-block;
		box-sizing: border-box;
		text-align: center;
		cursor: pointer;
		vertical-align: middle;
		zoom: 1;
		position: relative;
		background-color: transparent;
		border-radius: 4px;
		box-shadow: none;
		font-size: 14px;
		margin: 4px 1px;
		padding: 8px;
		color: #323338;
		outline: none;

		i {
			pointer-events: none;
		}

		&:hover {
			text-decoration: none;
			background-color: #E6E9F3;
			color: #323338 !important;
		}
	}

}
