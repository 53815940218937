.settings-container {
	.padding-right {
		padding-right: 15px;
	}

	.remove-icon {
		color: #BABABA;

		&:hover {
			color: #e6352b;
		}
	}
}

.columns-table {
	border: 1px solid smart-scale($table-background, $table-color-scale);

	input, select {
		margin: 0;
	}

	.fa-times {
		color: #BABABA;

		&:hover {
			color: #e6352b;
		}
	}

	.fa-bars {
		color: #BABABA;
	}

	tr.ui-state-default {
		border: 0;
	}

	tr.ui-placeholder {
		background-color: #BABABA !important;
	}


}

#linktableReveal {
	input[type="checkbox"], input[type="radio"] {
		margin: 0;
	}

	.margin-bottom{
		margin-bottom: 15px;
	}
}
