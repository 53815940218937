.page-website-onpageadvice {

	.competitors {
		.competitor {
			border-bottom: 1px solid #ddd;
			margin-bottom: 20px;
			padding-bottom: 10px;
			position: relative;

			strong {
				display: block;
				margin-bottom: 10px;
			}

			a.remove-competitor {
				position: absolute;
				right: 0;
				top: 0;

				* {
					pointer-events: none;
				}
			}
		}
	}

}