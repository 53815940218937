.url-container {
	.stat-container {
		text-align: center;
		padding: 10px 0;
		margin-bottom: 20px;

		&:last-child {
			margin-bottom: 0;
		}

		.number {
			font-size: 28px;
			margin: 0;
		}
	}

	.lastimport {
		font-size: 10px;
		color: hsla(0, 0%, 64%, .92);
	}

}

.table-container {
	padding: 0;

	h2 {
		padding: 10px;
		margin: 0 !important;
	}

	table {
		font-size: 12px;

		thead {
			th {
				color: #4F5257;
				border-bottom: 1px solid #ddd;
			}
		}

		td {
			max-width: 400px;

			&.nowrap {
				white-space: nowrap;
			}
		}
	}

	.archive-link {
		display: block;
		margin-left: 15px;
		font-size: 12px;
		margin-bottom: 10px;
		margin-top: 5px;

		&:before {
			content: '\f105';
			display: inline-block;
			font-family: "Font Awesome 5 Free";
			font-weight: 700;
			font-size: 12px;
			padding-right: 10px;
		}
	}

	.tier1 {
		margin: 0;

		a {
			line-height: 1;

			&:after {
				font-size: 10px;
				top: -2px;
			}
		}
	}

	td.td-buttons {
		white-space: nowrap;
	}
}