.block-holder {
	display: flex;
	flex-wrap: wrap;
	margin-top: -1px;
	margin-left: -1px;
	margin-bottom: 10px;

	.block {
		max-width: 175px;
		flex-grow: 1;
		padding: 24px;
		border-bottom: 1px solid rgba(10,10,10,0.25);
		border-right: 1px solid rgba(10,10,10,0.25);
		border-top: 1px solid transparent;
		cursor: pointer;

		&:hover {
			border-top: 1px solid rgba(10,10,10,0.25);
			background-color: #f5f5f5;
		}

		i.fal:before {
			content: "\f0c8";
		}

		&.selected {
			color: #fff;
			border-bottom: 0;
			border-right: 0;

			i.fal:before {
				content: "\f14a";
			}

			.compare .number-line .line {
				border-top-color: #fff;
			}
		}

		$colors: rgba(66,133,244, 1), rgba(94,53,177, 1), rgba(0,137,123,1), rgba(232,113,10,1),
		rgba(255,214,0, 1), rgba(1,87,155,1), rgba(198,40,40,1), rgba(0,191,165,1),
		rgba(121,85,72,1), rgba(213,0,249,1), rgba(158,157,36,1);

		@for $i from 1 through length($colors) {
			&:nth-child(#{$i}).selected {
				background-color: nth($colors, $i);

				&:hover {
					background-color: rgba(red(nth($colors, $i)), green(nth($colors, $i)), blue(nth($colors, $i)), 0.85);
				}
			}
		}

		.title {
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			font-size: 11px;
		}

		.number {
			font: 400 32px Roboto,RobotoDraft,Helvetica,Arial,sans-serif;
			margin-top: 8px;
		}

		.compare {
			.number-line {
				display: flex;
				gap: 10px;
				align-items: center;
				justify-content: space-between;
				margin-top: 8px;

				.number {
					margin-top: 0;
					font: 400 18px Roboto,RobotoDraft,Helvetica,Arial,sans-serif;
				}

				.line {
					width: 30px;
					border-top: solid 1px #4F5257;
					display: flex;
					clear: both;

					&.dashed {
						border-top-style: dashed;
					}
				}
			}

			.change {
				margin-top: 8px;
				font: 600 14px Roboto,RobotoDraft,Helvetica,Arial,sans-serif;

				span {
					font-weight: normal;
				}
			}

			.current-period, .previous-period {
				font-size: 10px;
			}
		}

	}
}