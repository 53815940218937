.graph-buttons {
	display: flex;
	flex-wrap: wrap;
	gap: 10px;

	.graph-button {
		display: flex;
		gap: 10px;
		justify-content: space-between;
		max-width: 252px;
		outline: none;
		padding-left: 12px;
		background-color: #d3e3fd;
		border-radius: 32px;
		cursor: pointer;
		height: 32px;
		letter-spacing: .25px;
		line-height: 16px;
		transition: background-color .125s cubic-bezier(0.4,0,0.2,1);
		white-space: nowrap;
		align-items: center;

		&:hover {
			background-color: #a8c7fa;
		}

		.text {
			flex: 1 1 auto;
			line-height: 20px;
			max-width: 200px;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			font-weight: 500;
			pointer-events: none;
		}

		i {
			padding-right: 15px;
			pointer-events: none;
		}

		&.has-remove i {
			pointer-events: auto;
		}
	}

	.new-button {
		display: flex;
		gap: 10px;
		justify-content: space-between;
		max-width: 252px;
		outline: none;
		padding-right: 12px;
		background-color: transparent;
		border-radius: 32px;
		cursor: pointer;
		height: 32px;
		letter-spacing: .25px;
		line-height: 16px;
		transition: background-color .125s cubic-bezier(0.4,0,0.2,1);
		white-space: nowrap;
		align-items: center;
		position: relative;

		&:hover {
			background-color: #cfd8dc;
		}

		.text {
			flex: 1 1 auto;
			line-height: 20px;
			max-width: 200px;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			font-weight: 500;
			pointer-events: none;
		}

		i {
			padding-left: 10px;
			pointer-events: none;
		}

		.new-dropdown {
			position: absolute;
			max-width: 192px;
			min-width: 192px;
			left: 50%;
			top: 50%;
			max-height: 232px;
			transform: translateZ(0);
			transition: max-width 0.2s cubic-bezier(0,0,0.2,1),max-height 0.2s cubic-bezier(0,0,0.2,1),opacity 0.1s linear;
			background: #fff;
			border: 0;
			border-radius: 2px;
			box-shadow: 0 8px 10px 1px rgba(0,0,0,.14), 0 3px 14px 2px rgba(0,0,0,.12), 0 5px 5px -3px rgba(0,0,0,.2);
			box-sizing: border-box;
			opacity: 1;
			outline: 1px solid transparent;
			z-index: 2000;
			padding: 16px 0;
			display: none;

			ul {
				list-style: none;
				margin: 0;
				padding: 0;

				a {
					display: block;
					outline: none;
					overflow: hidden;
					padding: 0 24px;
					font-size: 14px;
					line-height: 40px;
					height: 40px;
					color: #4F5257;

					&:hover {
						background-color: #eee;
					}
				}
			}
		}

		&.dropdown {
			background-color: #cfd8dc;

			.new-dropdown {
				display: block;
			}
		}
	}
}
