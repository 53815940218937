datatable {
	display: block;

	.table-holder {
		border: 1px solid rgba(10,10,10,0.25);
		margin-top: 20px;
	}

	&[data-connected-chart] .table-holder {
		margin-top: 0;
		border-top: 0;
	}

	.table-container {
		display: flex;
		gap: 20px;

		&.not-loaded {
			justify-content: center;
		}

		.break {
			flex-basis: 100%;
			height: 0;
		}

		.no-data {
			padding: 176px 0;
			text-align: center;
			color: rgba(0,0,0,.38);
			font-size: 15px;
		}
	}

	&[data-datatable-id="table-keyword-reach"] .table-container.compare {
		overflow-x: scroll;

		& > table {
			min-width: 2200px;
		}
	}

	th {
		padding-top: 20px;
		padding-bottom: 15px;

		&.with-relative-switch {
			display: flex;
			gap: 5px;
		}

		&.sortable {
			a {
				position: relative;
				display: flex;
				height: 18px;
				justify-items: center;
				color: rgba(79,82,87,0.6);
				font-weight: normal;
				padding-right: 20px;

				&:hover {
					color: rgba(79,82,87,0.8);
				}

				* {
					pointer-events: none;
				}

				span {
					position: relative;
					display: inline-block;
					margin-left: 5px;
					margin-top: -5px;

					&:before {
						position: absolute;
						content: '';
						top: -1px;
						left: 0;
						display: inline-block;
						width: 0;
						height: 0;
						border: solid 5px transparent;
						margin: 4px 4px 0 3px;
						background: transparent;
						border-bottom: solid 7px rgba(79, 82, 87, 0.2);
						border-top-width: 0;
					}

					&:after {
						position: absolute;
						content: '';
						top: 10px;
						left: 0;
						display: inline-block;
						width: 0;
						height: 0;
						border: solid 5px transparent;
						margin: 4px 4px 0 3px;
						background: transparent;
						border-top: solid 7px rgba(79, 82, 87, 0.2);
						border-bottom-width: 0;
					}
				}
			}

			&.text-right a {
				justify-content: flex-end;
				padding-right: 0;

				span {
					margin-right: 5px;

					&:before {
						left: auto;
						right: 0;
					}

					&:after {
						left: auto;
						right: 0;
					}
				}
			}
		}

		&.sortable[data-sort="true"] {
			a {
				color: #4F5257;
				font-weight: bold;
			}

			&[data-order="ASC"] {
				span:before {
					border-bottom: solid 7px rgba(79, 82, 87, 0.6);
				}

				span:after {
					border-top: solid 7px rgba(79, 82, 87, 0.1);
				}
			}

			&[data-order="DESC"] {
				span:before {
					border-bottom: solid 7px rgba(79, 82, 87, 0.1);
				}

				span:after {
					border-top: solid 7px rgba(79, 82, 87, 0.6);
				}
			}
		}

		&.col-url {
			width: 70%;
		}

		.relative-container {
			display: flex;
			gap: 5px;

			span {
				font-weight: normal;
			}

			.switch {
				display: inline-block;
				height: 17px;
				position: relative;
				width: 30px;

				input {
					display:none;
				}
			}

			.slider {
				background-color: #ccc;
				bottom: 0;
				cursor: pointer;
				left: 0;
				position: absolute;
				right: 0;
				top: 0;
				transition: .4s;

				&:before {
					background-color: #fff;
					bottom: 2px;
					content: "";
					height: 13px;
					left: 2px;
					position: absolute;
					transition: .4s;
					width: 13px;
				}
			}

			input:checked + .slider {
				background-color: #66bb6a;
				&:before {
					transform: translateX(13px);
				}
			}

			.slider.round {
				border-radius: 34px;
				&:before {
					border-radius: 50%;
				}
			}
		}

	}

	&[data-table-id="table-average-ctr"] table {
		width: auto;

		th.position {
			text-align: left;
			max-width: 80px;
		}

		th.ctr {
			text-align: right;
			width: 100px;
		}

		tr td:nth-child(2) {
			text-align: right;
		}

	}

	div.placeholder {
		position: relative;
		cursor: pointer;
		max-width: 100%;
		overflow: hidden;

		.icon-container {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;

			.lds-dual-ring {
				display: inline-block;
				width: 80px;
				height: 80px;
			}
			.lds-dual-ring:after {
				content: " ";
				display: block;
				width: 100px;
				height: 100px;
				margin: 8px;
				border-radius: 50%;
				border: 6px solid rgba(0, 0, 0, 0.2);
				border-color: rgba(0, 0, 0, 0.2) transparent rgba(0, 0, 0, 0.2) transparent;
				animation: lds-dual-ring 1.2s linear infinite;
			}
			@keyframes lds-dual-ring {
				0% {
					transform: rotate(0deg);
				}
				100% {
					transform: rotate(360deg);
				}
			}

			i {
				font-size: 100px;
				color: #d2d2d2;
			}

			.text {
				color: #d2d2d2;
			}

		}

		table.placeholder {
			width: 80%;
			margin: 0 auto;

			tr {
				th,
				td {
					border-right: 1px solid #ddd;
				}

				th:last-child,
				td:last-child {
					border-right: 0;
				}
			}

			th div {
				background-color: rgba(79,82,87,0.4);
				width: 100%;
				max-width: 100px;
				height: 20px;
			}

			td div {
				background-color: rgba(0,0,0,0.05);
				height: 20px;
			}
		}
	}

	td {
		&.date {
			width: 100px;
		}

		a.external {
			display: inline-block;
			pointer-events: auto;
			color: #4F5257;

			&:after {
				content: "\00A0";
				display: inline-block;
				margin-left: 10px;
				min-width: 12px;
			}

			&:hover:after {
				content: "\f14c";
				font-family: "Font Awesome 6 Pro";
				font-weight: 300;
				display: inline-block;
				margin-left: 10px;
				-webkit-font-smoothing: antialiased;
				font-style: normal;
				font-variant: normal;
				text-rendering: auto;
				line-height: 1;
			}
		}

		&.buttons {
			a {
				margin-right: 10px;

				&:last-child {
					margin-right: 0;
				}

				&.disabled {
					color: #afafaf;
					cursor: default;
				}
			}
		}

		.flex-container {
			display: flex;

			.value {
				flex-grow: 1;
			}

			.actions {
				display: none;
				gap: 8px;

				a {
					color: #4F5257;

					&:hover {
						color: lighten(#4F5257, 20%);
					}
				}

				i {
					pointer-events: none;
				}
			}
		}

		&.red {
			color: red;
		}

		&.green {
			color: green;
		}

	}

	tr:hover td .flex-container .actions {
		display: flex;
	}


	.table-nav {
		display: flex;
		margin-top: 20px;

		.totalresults {
			padding-left: 10px;
		}

		.paginator {
			flex: 1;
			display: flex;
			justify-content: center;
		}

		.limiter {
			padding-right: 10px;

			select {
				margin-bottom: 0;
			}
		}
	}

	.block-holder {
		margin-bottom: 0;

		&:not(.border-bottom) .block {
			border-bottom: 0;
		}
	}

	.group-block {
		position: relative;
		display: flex;
		gap: 10px;
		border-top: 1px solid rgba(10,10,10,0.25);
		border-bottom: 1px solid rgba(10,10,10,0.25);
		justify-content: space-between;
		padding-left: 10px;
		padding-right: 10px;
		overflow-x: scroll;

		.block {
			padding: 10px 10px 0;

			a {
				text-transform: uppercase;
				color: rgba(79,82,87,0.6);
				font-weight: 600;
				display: block;
				padding: 0 10px 10px;

				&:hover {
					color: rgba(79,82,87,0.8);
				}

				&.active {
					color: #4F5257;
					border-bottom: solid 2px #000;
				}
			}
		}
	}

	&[data-connected-chart] .group-block {
		border-top: 0;
	}

}

.success-message {
	margin: 20px 0;
	color: $success-color;
}

.import-message {
	border-top: 1px solid rgba(10,10,10,0.25);
	margin: 20px 0;
	padding: 20px 0 0;
}