
.page-website-settings {
	.choose-google-token {
		padding: 10px;
		border: 1px solid rgba(10,10,10,0.25);
		margin-top: 20px;
	}

	.subtext {
		font-size: 10px;
		margin-top: 4px;
		margin-left: 5px;
	}
}

.image-container {
	position: relative;

	.reload-screenshot {
		position: absolute;
		right: 25px;
		bottom: 10px;
		cursor: pointer;
	}
}

.main-keyword-info {
	color: $primary-color;
	cursor: pointer;
}

.custom-gpt {
	.external-link:after {
		top: 0;
	}

	i.far {
		margin-left: 10px;
	}
}