chart {
	display: block;

	.chart-holder {
		border: 1px solid rgba(10,10,10,0.25);
		margin-top: 20px;


		.chart-options {
			display: flex;
			justify-content: space-between;
		}

		.view-container {
			margin-left: 20px;
			display: flex;
			gap: 5px;
			align-items: center;

			.text {
				font-size: 10px;
			}

			.button-group {
				margin-bottom: 0;
			}

			a.button {
				background-color: rgba(0,0,0,0.15);
				padding-top: 4px;
				padding-bottom: 4px;

				&.selected {
					background-color: rgba(0,0,0,0.3);
				}

				&:hover {
					background-color: rgba(0,0,0,0.2);
				}
			}
		}

		.options-container {
			position: relative;
			z-index: 9;

			.options-holder {
				display: flex;
				justify-content: right;
				gap: 10px;
				margin-right: 10px;

				.option-wrapper {
					position: relative; // Maak dit de referentie voor de dropdown
				}

				.option-dropdown {
					display: none;
					position: absolute;
					top: 100%;
					left: 0;
					background-color: white;
					border: 1px solid #ccc;
					box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
					z-index: 10;
					padding: 10px;
					white-space: nowrap;

					.option-dropdown-inner {
						padding: 4px 0;
						cursor: pointer;

						&.selected {
							i {
								--fa-secondary-opacity: 1;
							}

							&[data-name="core"] {
								--fa-secondary-color: #00ffff;
							}

							&[data-name="spam"] {
								--fa-secondary-color: #00ff15;
							}

							&[data-name="review"] {
								--fa-secondary-color: #ffd300;
							}

							&[data-name="helpful content"] {
								--fa-primary-color: #ffffff;
								--fa-secondary-color: #ff9500;
							}

							&[data-name="link spam"] {
								--fa-primary-color: #ffffff;
								--fa-secondary-color: #ff0040;
							}

							&[data-name="other"] {
								--fa-primary-color: #ffffff;
								--fa-secondary-color: #ff00ff;
							}


						}

					}
				}

				.option {
					font-size: 12px;
					cursor: pointer;
					padding: 5px 10px;
					border: 1px solid transparent;
					transition: background-color 0.3s ease, border-color 0.3s ease;
					cursor: pointer;
					position: relative;

					* {
						pointer-events: none;
					}

					&:hover {
						color: rgba(79,82,87,0.85);
					}

					&.selected[data-name="trendlines"] i {
						--fa-secondary-opacity: 1;
						--fa-primary-color: #ffffff;
						--fa-secondary-color: #241739;
					}

					&.selected[data-name="seasonality"] i {
						--fa-secondary-opacity: 1;
						--fa-primary-color: #ffffff;
						--fa-secondary-color: #241739;
					}

					&.selected[data-name="workannotations"] i {
						--fa-secondary-opacity: 1;
						--fa-primary-color: #ffffff;
						--fa-secondary-color: #00e396;
					}

					&.selected[data-name="annotations"] i {
						--fa-secondary-opacity: 1;
						--fa-primary-color: #ffffff;
						--fa-secondary-color: #008ce3;
					}

					&.selected[data-name="algo"] i {
						--fa-secondary-opacity: 1;
						--fa-primary-color: #ffffff;
						--fa-secondary-color: #241739;
					}

				}

				.option-wrapper:hover .option-dropdown {
					display: block;
				}

				.option-wrapper:hover .option {
					background-color: white;
					border-color: #ccc;
					box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
					color: rgba(79, 82, 87, 0.85);
				}
			}
		}

		.chart-container {
			min-height: 300px;
			margin-top: 10px;
			background: url("/img/logo_searchking_grey_nopayoff.svg") no-repeat center center;
			background-size: 50%;

			.ggl-tooltip {
				border: 1px solid #E0E0E0;
				font-family: Arial, Helvetica;
				font-size: 10pt;
				padding: 12px 12px 12px 12px;
			}

			.ggl-tooltip div {
				padding-top: 6px;
			}

			.ggl-tooltip span {
				font-weight: bold;
			}

			.google-visualization-tooltip {
				white-space: nowrap;
			}

			&.not-loaded {
				cursor: pointer;
			}

			.empty-chart {
				margin: 30px;
				width: calc(100% - 60px);
				height: 300px;
				border: 2px solid rgba(0, 0, 0, 0.1);
				border-right: 0;
				border-top: 0;
				position: relative;
				cursor: pointer;
				pointer-events: none;

				* {
					pointer-events: none;
				}

				.icon-container {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					display: flex;
					align-items: center;
					justify-content: center;
					flex-direction: column;

					.lds-dual-ring {
						display: inline-block;
						width: 80px;
						height: 80px;
					}
					.lds-dual-ring:after {
						content: " ";
						display: block;
						width: 100px;
						height: 100px;
						margin: 8px;
						border-radius: 50%;
						border: 6px solid rgba(0, 0, 0, 0.2);
						border-color: rgba(0, 0, 0, 0.2) transparent rgba(0, 0, 0, 0.2) transparent;
						animation: lds-dual-ring 1.2s linear infinite;
					}
					@keyframes lds-dual-ring {
						0% {
							transform: rotate(0deg);
						}
						100% {
							transform: rotate(360deg);
						}
					}

					i {
						font-size: 100px;
						color: rgba(0, 0, 0, 0.2);
					}

					.text {
						color: rgba(0, 0, 0, 0.2);
					}

				}

				svg {
					width: 100%;
					height: 100%;
				}

				.chart-line {
					fill: none;
					stroke: #d2d2d2;
					stroke-width: 1;
				}

				.line {
					border-top: 1px solid rgba(0, 0, 0, 0.1);
					position: absolute;
					bottom: 50px;
					height: 1px;
					width: 100%;
				}

				.line-2 {
					bottom: 100px;
				}

				.line-3 {
					bottom: 150px;
				}

				.line-4 {
					bottom: 200px;
				}

				.line-5 {
					bottom: 250px;
				}
			}

			.apexcharts-tooltip-marker {
				border-radius: 0;
				width: 14px;
				height: 2px;
				border-top-width: 2px;
				border-top-style: solid;
				display: block;
				margin: 0;

				&.dashed {
					border-top-style: dashed;
				}
			}

			.tooltip-group-title {
				font-weight: bold;
				order: 1;
				display: flex;
				padding: 0 10px;
				font-size: 12px;
			}

			.tooltip-group {
				margin-bottom: 10px;
				font-size: 12px;
				padding: 0 10px;

				table {
					margin-bottom: 0;
					margin-top: 5px;
				}

				tbody th, tbody td {
					padding: 5px;
				}

				td.value {
					font-weight: 600;
				}

				td.trend {
					font-size: 11px;
				}
			}
		}
	}
}

.filter-reveal,
.chart-reveal {
	padding: 0;

	.reveal-header {
		background-color: $primary-color;
		color: #fff;
		padding: 20px;
		margin-bottom: 20px;

		&.padding-bottom {
			padding-bottom: 20px;
		}

		.tabs {
			border: 0;
			margin-top: 20px;
			background-color: transparent;

			a {
				color: #fff;
				font-size: 14px;
				text-transform: uppercase;

				&:hover, &:focus {
					background-color: rgba(255,255,255,0.4);
					border-bottom: solid 2px rgba(255,255,255,0.4);
				}
			}

			li.is-active a {
				border-bottom: solid 2px #fff;
				background-color: transparent;
			}
		}

		.title {
			font-weight: bold;
			font-size: 18px;
		}

		.close-button {
			color: #fff;
			font-size: 42px;
			font-weight: bold;
			top: 12px;
		}
	}

	.tabs-content {
		background: transparent;
		border: 0;
	}

	.button-bar {
		border-top: 1px solid rgba(10,10,10,0.25);
		display: flex;
		justify-content: end;
		padding: 20px 20px 0;

		.button {
			margin-left: 15px;
		}
	}

	.custom-daterange {
		margin-bottom: 0;
	}

	.dates-container {
		display: flex;
		justify-content: space-between;
		max-width: 290px;
		margin: 0 0 20px 20px;

		p, input {
			margin: 0;
		}

		p {
			font-size: 12px;
			font-weight: 300;
		}

		.dash {
			padding-top: 24px;
		}
	}

	div.error {
		color: red;
		font-size: 10px;
		padding-left: 20px;
		margin-top: -10px;
		margin-bottom: 20px;
	}

	.width-50 {
		max-width: 50%;
	}

	.options-container {
		max-height: 400px;
		overflow-y: scroll;
	}

}

a[data-branded-setting] i {
	pointer-events: none;
}

.branded-filter {
	padding-top: 5px;
	padding-right: 10px;

	small {
		margin-right: 5px;
	}

	&.next-line {
		padding-top: 0;
		margin-top: -15px;
		padding-bottom: 20px;
	}
}