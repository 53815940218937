.website-card.callout {
	color: #4F5257;
	cursor: pointer;
	position: relative;

	h2.title {
		font-size: 18px;
		margin: 0;
	}

	small {
		color: hsla(0,0%,64%,.92);
		letter-spacing: .01em;
		font-size: 85%;
		margin-bottom: 20px;
		display: block;

		&.no-margin-bottom {
			margin-bottom: 0;
		}
	}

	.profile-photo {
		position: absolute;
		top: 1rem;
		right: 1rem;
	}
}

h2.title_customergroup {
	font-size: 24px;
	border-bottom: solid 1px rgba(10, 10, 10, 0.25);
	margin-bottom: 30px;
	margin-top: 30px;

	&:first-child {
		margin-top: 0;
	}
}

.website-name {
	font-size: 30px;
	font-weight: bold;
	padding-left: 20px;
	color: rgba(10, 10, 10, 0.6);
}

.website-view .button {
	margin-bottom: 0;
}