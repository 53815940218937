.page-searchconsole {
	table.searchconsole-table {
		td.active {
			color: green;
		}
		td.unavailable {
			color: red;
		}
	}
}

.page-searchconsole-property {
	table.property-data {
		td.green {
			color: green;
		}
		td.red {
			color: red;
		}
	}
}