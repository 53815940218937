.competitors-container {
	.padding-right {
		padding-right: 15px;
	}
}

#addCompetitorReveal {
	.schedule {
		display: none;
	}
}