.callout {
	color: #4F5257;
	position: relative;

	h2.title {
		font-size: 18px;
		margin: 0 0 20px 0;
	}

	h3.title {
		font-size: 16px;
		margin: 0 0 20px 0;
	}

	.toggler {
		position: absolute;
		right: 2rem;
		top: 2rem;
		font-size: 30px;
		transition-duration: 0.3s;
		transition-property: transform;
	}

	.content {
		max-height: 0;
		overflow: hidden;
		transition-duration: 0.5s;
		transition-property: max-height;
	}

	&.open {
		.toggler {
			transform: rotate(180deg);
			-webkit-transform: rotate(180deg);
		}

		.content {
			max-height: 10000px;
		}
	}

	.project-information {
		div.item {
			display: flex;
			gap: 10px;
			align-items: center;
			margin-bottom: 10px;

			&:last-child {
				margin-bottom: 0;
			}

			a {
				display: block;
				position: relative;

				&.external-link:after {
					top: -1px;
				}
			}
		}
	}

}

.title-container {
	display: flex;
	justify-content: space-between;
	align-items: baseline;

	h2 {
		flex-grow: 1;
	}

	.export-container {
		position: relative;

		i.fa-spin {
			position: absolute;
			left: -20px;
			top: 8px;
		}

		> a {
			color: #4F5257;
			display: block;
			padding: 5px 10px;
			white-space: nowrap;

			* {
				pointer-events: none;
			}

			&:hover {
				background-color: #ddd;
			}

			span {
				padding-left: 8px;
				text-transform: uppercase;
			}

			&.active {
				background-color: #ddd;
			}
		}

		.export-dropdown {
			position: absolute;
			right: 0;
			top: 31px;
			transition: left 0.2s cubic-bezier(0,0,0.2,1),max-width 0.2s cubic-bezier(0,0,0.2,1),max-height 0.2s cubic-bezier(0,0,0.2,1),opacity 0.05s linear,top 0.2s cubic-bezier(0,0,0.2,1);
			transform: translateZ(0);
			background: #fff;
			border: 0;
			border-radius: 2px;
			box-shadow: 0px 8px 10px 1px rgba(0,0,0,.14), 0px 3px 14px 2px rgba(0,0,0,.12), 0px 5px 5px -3px rgba(0,0,0,.2);
			box-sizing: border-box;
			opacity: 1;
			outline: 1px solid transparent;
			padding: 15px 0;
			z-index: 2000;
			min-width: 250px;

			> a {
				display: block;
				white-space: nowrap;
				padding: 10px 20px;
				color: #4F5257;

				* {
					pointer-events: none;
				}

				i {
					margin-right: 10px;
					font-size: 18px;
				}

				&:hover {
					background-color: #eee;
				}
			}
		}
	}

	.add-container,
	.add-annotation {
		position: relative;

		> a {
			color: #4F5257;
			display: block;
			padding: 5px 10px;

			* {
				pointer-events: none;
			}

			&:hover {
				background-color: #ddd;
			}

			span {
				padding-left: 8px;
				text-transform: uppercase;
			}

			&.active {
				background-color: #ddd;
			}
		}

		.add-dropdown {
			position: absolute;
			right: 0;
			top: 31px;
			transition: left 0.2s cubic-bezier(0,0,0.2,1),max-width 0.2s cubic-bezier(0,0,0.2,1),max-height 0.2s cubic-bezier(0,0,0.2,1),opacity 0.05s linear,top 0.2s cubic-bezier(0,0,0.2,1);
			transform: translateZ(0);
			background: #fff;
			border: 0;
			border-radius: 2px;
			box-shadow: 0px 8px 10px 1px rgba(0,0,0,.14), 0px 3px 14px 2px rgba(0,0,0,.12), 0px 5px 5px -3px rgba(0,0,0,.2);
			box-sizing: border-box;
			opacity: 1;
			outline: 1px solid transparent;
			padding: 15px 0;
			z-index: 2000;
			min-width: 250px;

			> a {
				display: block;
				white-space: nowrap;
				padding: 10px 20px;
				color: #4F5257;

				* {
					pointer-events: none;
				}

				i {
					margin-right: 10px;
					font-size: 18px;
				}

				&:hover {
					background-color: #eee;
				}
			}
		}
	}
}

$metric-bar-height: 20px;

.brandedterms-table {
	margin-bottom: 0;

	td.term {
		font-family: monospace;
	}

	a {
		color: #4F5257;

		&:hover {
			color: red;
		}

		* {
			pointer-events: none;
		}

	}
}

.add-brandedterm {
	width: 80%;
	padding: 0 10px;

	input {
		box-shadow: none;
		border: 1px solid #fff;
		margin-bottom: 0;
		font-family: monospace;

		&:hover {
			border: 1px solid #cacaca;
		}

		&:focus {
			box-shadow: 0 0 5px #cacaca;

			+ .input-group-button .button {
				opacity: 1;
			}
		}
	}

	.button {
		padding-top: 4px !important;
		opacity: 0;

		* {
			pointer-events: none;
		}
	}

}

.website-container {
	strong.margin-top {
		display: block;
		margin-top: 30px;
	}

	.date-information {
		font-size: 10px;
	}

	.metric-chart {
		display: flex;
		padding-bottom: 16px;
		font-size: 12px;
		font-weight: bold;

		.bar {
			display: inline-block;
			line-height: $metric-bar-height;
			text-align: center;
			color: #fff;
			border-radius: ($metric-bar-height/2) 0 0 ($metric-bar-height/2);
			text-align: left;
			padding-left: 10px;
			min-width: 38px;
			position: relative;

			&:after {
				width: ($metric-bar-height/2);
				height: $metric-bar-height;
				position: absolute;
				right: ($metric-bar-height/2*-1);
				content: '';
			}
		}

		.average {
			background: $lighthouse-orange;
			width: ($metric-bar-height/2);
			height: $metric-bar-height;
			color: #000;
			z-index: 1;
			&:after {
				background-color: $lighthouse-orange;
			}
		}

		.fast,
		.fast:after {
			background-color: $lighthouse-green;
		}

		.slow {
			background: $lighthouse-red;
			border-radius: ($metric-bar-height/2);
			z-index: 2;
		}

		.full-100 {
			border-radius: ($metric-bar-height/2);

			&:after {
				background-color: transparent;
			}
		}
	}

	.lab-data {
		.fa-arrow-up {
			color: #4caf50;
		}

		.fa-arrow-down {
			color: #e6352b;
		}

		.fa-circle {
			color: #03a9f4;
			font-size: 11px;
		}

		.grey-bg {
			background-color: #f1f1f1;
			padding: 20px;
		}

		.other-statistics {
			padding: 20px;
		}

		a.smaller {
			font-size: 12px;
			margin-top: 10px;
			display: block;
		}

		.fa-exclamation-triangle {
			color: $lighthouse-red;
			margin-left: 10px;
		}

		.first-column {
			border-left: solid 1px #ddd;
		}

		thead {
			th {
				color: #4F5257;
				border-bottom: 1px solid #ddd;
			}
		}

		.fas.fa-heart {
			color: $lighthouse-orange;
		}

		.fa-heart {
			cursor: pointer;
		}

		.color-green {
			color: $lighthouse-green;
		}

		.color-orange {
			color: $lighthouse-orange;
		}

		.color-red {
			color: $lighthouse-red;
		}


	}
}

.page-website-settings {
	.website-schedule-day {
		margin-bottom: 20px;

		p,
		input {
			margin-bottom: 0;
		}
	}

	.drive-connect-container {
		position: relative;

		.folder-icon {
			position: absolute;
			top: 0;
			left: 0;
			width: 20px;
		}

		.path {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			width: 230px;
			direction: rtl;
			text-align: left;
			margin-left: 5px;
			margin-right: 5px;
		}

		.edit-icon {
			position: absolute;
			top: 0;
			right: 0;
			width: 20px;

			i {
				cursor: pointer;
			}
		}


	}

	.searchconsole-logo {
		margin-bottom: 15px;
	}

	.no-sites {
		margin-bottom: 15px;
	}

}


.services {
	margin-bottom: 40px;

	img {
		max-width: 224px;
	}

	.button {
		margin: 0;
	}
}

table {
	td .flex-container {
		display: flex;

		.value {
			flex-grow: 1;
		}

		.actions {
			display: none;
			gap: 8px;

			a {
				color: #4F5257;

				&:hover {
					color: lighten(#4F5257, 20%);
				}
			}

			i {
				pointer-events: none;
			}
		}
	}

	tr:hover .actions {
		display: flex;
	}
}

table.framework-analysis {
	.fa-stack {
		font-size: 10px;
	}


	.fa-check,
	.fa-arrow-alt-up {
		color: green;
	}

	.fa-times,
	.fa-arrow-alt-down {
		color: red;
	}

	.compare-holder {
		display: inline-block;
		width: 13px;
		padding-left: 4px;
	}

	td.col-position {
		white-space: nowrap;
	}
}

.notification {
	position: fixed;
	bottom: -100px; // Start buiten het scherm
	left: 50%;
	transform: translateX(-50%);
	background-color: #241739;
	color: white;
	padding: 10px 20px;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
	transition: bottom 0.2s ease;

	&.show {
		bottom: 10px; // Eindpositie
	}
}

.cornerstone-holder,
.stage-holder,
.intention-holder {
	color: #cacaca;

	a {
		color: #cacaca;

		&:hover {
			color: darken(#cacaca, 20%);
		}

		&.active {
			color: #4F5257;
		}
	}
}

h2.title.no-margin-bottom {
	margin-bottom: 0;
}

.period_small {
	font-size: 10px;
	margin-bottom: 30px;
}

.actions-popup {
	display: flex;
	justify-content: space-between;
	align-items: stretch; // Zorg ervoor dat alle kinderen dezelfde hoogte hebben
	position: fixed;
	bottom: 10px;
	left: 50%;
	transform: translateX(-50%);
	background-color: white;
	padding: 0;
	color: #4F5257;

	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);

	& > div {
		display: flex;
		align-items: center;
		padding: 10px 0; // Verplaatst padding naar binnenste divs
	}

	.popup-count-wrapper {
		width: 63px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		text-align: center;
		cursor: default;
		background-color: #241739;
		color: white;

		div {
			font-size: 30px;
		}
	}

	.popup-text {
		font-size: 20px;
		margin-right: 30px;
		padding: 0 20px;
		background: white;
	}

	.popup-buttons {
		.button {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			margin: 0 20px 0 0;
			padding: 0;
			background-color: white;
			color: #4F5257;

			&:hover {
				color: lighten(#4F5257, 20%);
			}

			span {
				font-size: 16px;
			}

			i {
				margin-bottom: 5px;
			}
		}
	}

	.popup-close {
		border-left: 2px solid #241739;
		cursor: pointer;
		display: flex;
		width: 63px;
		cursor: pointer;
		text-align: center;
		flex-direction: column;
		justify-content: center;
		text-align: center;

		&:hover {
			color: lighten(#4F5257, 20%);
		}

	}
}

.alert-message {
	display: flex;

	p {
		margin: 0;
		padding-left: 20px;

		a {
			font-size: 11px;
		}
	}
}

table.seasonality {
	td {
		input {
			margin-bottom: 0;
		}

		i {
			cursor: pointer;
		}
	}
}