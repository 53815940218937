.page-website-crawler {

	table.crawl-configs {
		td:first-child {
			width: 60%;
		}

		td span.config-name {
			padding-right: 5px;
		}

		td span.label {
			margin-left: 10px;
		}

		td.icons {
			i {
				cursor: pointer;
				font-size: 16px;
				margin-right: 10px;

				&:last-child {
					margin-right: 0;
				}

				&:hover {
					color: $secondary-color;
				}

				&.fa-times:hover {
					color: red;
				}
			}
		}
	}

	.preset-container {
		margin-top: 20px;
	}

	.label-container {
		display: flex;
		flex-wrap: wrap;
		gap: 10px;
		border: 1px solid $primary-color;
		padding: 10px;

		.label {
			background-color: $secondary-color;
			i {
				margin-left: 5px;
				cursor: pointer;
			}
		}

		[contenteditable] {
			outline: 0 solid transparent;
			min-width: 40px;
		}

		&.disabled {
			background-color: #ededed;

			.label i {
				display: none;
			}

			[contenteditable] {
				display: none;
			}
		}


	}

	input.error {
		border: solid 1px red;
	}

	.margin-top-20 {
		margin-top: 20px;
	}

	.autocomplete-container {
		padding: 10px;
		border: 1px solid $primary-color;
		border-top: 0;
		max-height: 150px;
		overflow-y: auto;

		.autocomplete-item {
			padding: 5px 10px;

			&.highlighted {
				background-color: lightgray;
			}
		}
	}

}

.output {
	background-color: black;
	color: white;
	font-family: Menlo, Monaco, Consolas, "Courier New", monospace;
	padding: 20px;
	border-radius: 5px;
	overflow: auto;
	white-space: pre;
	height: 560px;
	max-height: 560px;
}